import request from '@/config/axios'

export const getTeacherListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/managers/teachers', params })

  return {data: res}
}

export const getTeacherClassListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/managers/teacherClasses', params })

  return {data: res}
}

export default {
  getTeacherListApi,
  getTeacherClassListApi
}
