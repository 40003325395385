import request from '@/config/axios'
import { getStudentClassName } from '../class'

export const classRecordDataApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/reports/classRecordData', params })

  let details = res.map((x) => {
    x.school_year = getStudentClassName(x.name, x.subject, x.school_year)
    return x
  }).filter((x) => !x.school_year.includes('已畢業'))


  return details
}


export default {
  classRecordDataApi
}
