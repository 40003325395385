import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import { useAppStore } from '@/store/modules/app'
import { toRaw } from 'vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      noNeedAuth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      noNeedAuth: true
    },
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        name: 'Main',
        redirect: '/tabs/tab2'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      //---------正課
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'tab3/rollcall',
        component: () => import('@/views/tab3/RollcallPage.vue'),
      },
      {
        path: 'tab3/auditionStudentEdit',
        component: () => import('@/views/tab3/AuditionStudentEditPage.vue'),
      },
      {
        path: 'tab3/feed',
        component: () => import('@/views/tab3/FeedPage.vue'),
      },
      {
        path: 'tab3/grade',
        component: () => import('@/views/tab3/GradePage.vue'),
      },
      {
        path: 'tab3/gradeTest',
        component: () => import('@/views/tab3/GradeTestPage.vue'),
      },
      {
        path: 'tab3/gradeTestEdit',
        component: () => import('@/views/tab3/GradeTestEditPage.vue'),
      },
      {
        path: 'tab3/report',
        component: () => import('@/views/tab3/ReportPage.vue'),
      },
      //---------缺課名單
      {
        path: 'tab3/absent',
        component: () => import('@/views/tab3/AbsentPage.vue'),
      },
      //---------輔導課
      {
        path: 'tab3/tab2',
        component: () => import('@/views/tab2/TutorPage.vue')
      },
      {
        path: 'tab3/tab2/rollcall',
        component: () => import('@/views/tab2/RollcallPage.vue'),
      },
      {
        path: 'tab3/tab2/feed',
        component: () => import('@/views/tab2/FeedPage.vue'),
      },
      {
        path: 'tab3/tab2/homework',
        component: () => import('@/views/tab2/HomeworkPage.vue'),
      },
      {
        path: 'tab4',
        component: () => import('@/views/Tab4Page.vue')
      },
      {
        path: 'tab4/studentList',
        component: () => import('@/views/tab4/StudentListPage.vue'),
      },
      {
        path: 'tab4/studentLeave',
        component: () => import('@/views/tab4/StudentLeavePage.vue'),
      },
      {
        path: 'tab4/studentChange',
        component: () => import('@/views/tab4/StudentChangePage.vue'),
      },
      {
        path: 'tab4/studentGraduate',
        component: () => import('@/views/tab4/StudentGraduatePage.vue'),
      },
      {
        path: 'tab4/studentGraduateAll',
        component: () => import('@/views/tab4/StudentGraduateAllPage.vue'),
      },
      {
        path: 'tab4/studentEdit',
        component: () => import('@/views/tab4/StudentEditPage.vue'),
      },
      {
        path: 'tab4/grade',
        component: () => import('@/views/tab4/GradePage.vue'),
      },
      {
        path: 'tab4/gradeTest',
        component: () => import('@/views/tab4/GradeTestPage.vue'),
      },
      {
        path: 'tab5',
        component: () => import('@/views/tab5Page.vue')
      },
      {
        path: 'tab5/paymentEdit',
        component: () => import('@/views/tab5/PaymentEditPage.vue'),
      },
      {
        path: 'tab6',
        component: () => import('@/views/tab6Page.vue')
      },
      {
        path: 'tab6/changePassword',
        component: () => import('@/views/tab6/ChangePasswordPage.vue')
      },
      {
        path: 'manager/tabA',
        component: () => import('@/views/manager/TabAPage.vue')
      },
      {
        path: 'manager/report',
        component: () => import('@/views/tab3/ReportPage.vue')
      },
      {
        path: 'manager/tabB',
        component: () => import('@/views/manager/TabBPage.vue')
      },
      {
        path: 'manager/teacher/classes',
        component: () => import('@/views/teacher/ClassesPage.vue')
      },
      {
        path: 'manager/teacher/diaryList',
        component: () => import('@/views/teacher/DiaryListPage.vue')
      },
      {
        path: 'manager/teacher/transcript',
        component: () => import('@/views/teacher/TranscriptPage.vue')
      },
      {
        path: 'manager/tabC',
        component: () => import('@/views/manager/TabCPage.vue')
      },
      {
        path: 'manager/tabD',
        component: () => import('@/views/manager/TabDPage.vue')
      },
      {
        path: 'manager/student/paymentUnpaid',
        component: () => import('@/views/student/paymentUnpaidPage.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const appStore = useAppStore()
  const user = toRaw(appStore.getUserInfo).user
  if (!to.matched.some(record => record.meta.noNeedAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!user && to.name != "Login") {
      next({ name: 'Login', hash: '#forced' });
    } else if ((to.name == "Login") && user) {
      next({ name: 'Main' });
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
