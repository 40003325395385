import request from '@/config/axios'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/payments', params })

  if (params.page) {
    res.data = res.data
    return res
  } else {
    return res
  }
}

export const getUnpaidListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/payments/unpaidList', params })

  if (params.page) {
    res.data = res.data
    return res
  } else {
    return res
  }
}

export const getUnpaidListByClassApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/payments/unpaidListByClass', params })

  if (params.page) {
    res.data = res.data
    return res
  } else {
    return res
  }
}

export const exportExcelApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/payments/exportExcel', params })
  return res
}
export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/payments/${data.id}`, data })
  } else {
    return await request.post({ url: '/payments', data })
  }
}

export const getApi = async (id: string): Promise<TableData> => {
  return await request.get({ url: `/payments/${id}` })
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/payments`, params: { ids } })
}

export default {
  getListApi,
  getUnpaidListApi,
  getUnpaidListByClassApi,
  exportExcelApi,
  saveApi,
  getApi,
  delApi
}
