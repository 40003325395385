import request from '@/config/axios'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/students', params })

  const changeClassNames = (x) => {
    x.class_names = x.classes ? x.classes.map((y) => y.subject + y.name).join(', ') : undefined
    return x
  }

  if (params.page) {
    res.data = res.data?.map(changeClassNames)
    return res
  } else {
    return res.map(changeClassNames)
  }
}

export const getSummaryApi = async (params: any): Promise<IResponse> => {
  const {students, attends, tests} = await request.get({ url: '/students/summary', params })

  const changeClassNames = (x) => {
    x.class_names = x.classes ? x.classes.map((y) => y.subject + y.name).join(', ') : undefined
    return x
  }

    return {theStudents: students.map(changeClassNames), theAttends: attends, theTests: tests}
}

export const getClassStudentsOption = async (params?: any): Promise<TableData[]> => {
  const res = await request.get({ url: '/students', params })

  return res.map((x) => ({ label: x.name, value: x.id }))
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/students/${data.id}`, data })
  } else {
    return await request.post({ url: '/students', data })
  }
}

export const importExcelApi = async ({ class_id, data }): Promise<IResponse> => {
  return await request.post({ url: '/students/importExcel', data: { class_id, data } })
}

export const checkExcelApi = async ({ class_id, data }): Promise<IResponse> => {
  return await request.post({ url: '/students/checkExcel', data: { class_id, data } })
}

export const getApi = async (id: string): Promise<TableData> => {
  return await request.get({ url: `/students/${id}` })
}

// export const detachApi = ({ class_id }) => {
//   return async (ids: string[] | number[]): Promise<IResponse> => {
//     return await request.post({ url: `/students/detach`, params: { ids, class_id } })
//   }
// }

export const leaveApi = async ({ class_id, student_id, changed_at }): Promise<TableData> => {
  return await request.post({
    url: `/students/leave`,
    params: { student_id, class_id, changed_at }
  })
}

export const changeApi = async ({
  class_id,
  move_to_class_id,
  student_id,
  changed_at
}): Promise<TableData> => {
  return await request.post({
    url: `/students/change`,
    params: { student_id, class_id, move_to_class_id, changed_at }
  })
}

export const graduateApi = async ({ class_id, student_id, changed_at }): Promise<TableData> => {
  return await request.post({
    url: `/students/graduate`,
    params: { student_id, class_id, changed_at }
  })
}


export const graduateAllApi = async ({ class_id, changed_at }): Promise<TableData> => {
  return await request.post({
    url: `/students/graduateAll`,
    params: { class_id, changed_at }
  })
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/students`, params: { ids } })
}

export const getStudentByNameAndBirthdayApi = async (name: string, birthday: string): Promise<TableData> => {
  return await request.get({ url: `/students/getStudentByNameAndBirthday`, params: { name, birthday } })
}

//列出旁聽學生
export const auditionStudentsApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/students/auditionStudents', params })
  return res
}

export const saveAuditionStudentsApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/students/auditionStudents/${data.id}`, data })
  } else {
    return await request.post({ url: '/students/auditionStudents', data })
  }
}

export const confirmAuditionStudentApi = async (data) => {
  return await request.post({
    url: `/students/confirmAuditionStudent/${data.id}`,
    params: { class_id: data.class_id, class_roll_call_id: data.class_roll_call_id }
  })
} 

export const removeAuditionStudentApi = async (data) => {
  return await request.post({
    url: `/students/removeAuditionStudent/${data.id}`,
    params: { class_id: data.class_id }
  })
} 

export default {
  getListApi,
  getSummaryApi,
  saveApi,
  importExcelApi,
  checkExcelApi,
  getApi,
  delApi,
  leaveApi,
  changeApi,
  graduateApi,
  graduateAllApi,
  getClassStudentsOption,
  getStudentByNameAndBirthdayApi,
  auditionStudentsApi,
  saveAuditionStudentsApi,
  confirmAuditionStudentApi,
  removeAuditionStudentApi
}
